// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-angol-js": () => import("F:\\Websites\\React\\zkmsz-erettsegi-portal\\src\\pages\\angol.js" /* webpackChunkName: "component---src-pages-angol-js" */),
  "component---src-pages-index-js": () => import("F:\\Websites\\React\\zkmsz-erettsegi-portal\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informatika-js": () => import("F:\\Websites\\React\\zkmsz-erettsegi-portal\\src\\pages\\informatika.js" /* webpackChunkName: "component---src-pages-informatika-js" */),
  "component---src-pages-irodalom-js": () => import("F:\\Websites\\React\\zkmsz-erettsegi-portal\\src\\pages\\irodalom.js" /* webpackChunkName: "component---src-pages-irodalom-js" */),
  "component---src-pages-matematika-js": () => import("F:\\Websites\\React\\zkmsz-erettsegi-portal\\src\\pages\\matematika.js" /* webpackChunkName: "component---src-pages-matematika-js" */),
  "component---src-pages-tortenelem-js": () => import("F:\\Websites\\React\\zkmsz-erettsegi-portal\\src\\pages\\tortenelem.js" /* webpackChunkName: "component---src-pages-tortenelem-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "F:\\Websites\\React\\zkmsz-erettsegi-portal\\.cache\\data.json")

